<template>
  <div class="transmit_sub_container">
    <div class="transmittal_slip_filter adj_sur">
      <div class="label_element_cover">
      <label>Date</label>
      <flat-pickr
        v-model="post_data.date"
        :config="configDate"
        placeholder="Select Date"
        @on-close="fetchSlip"
      ></flat-pickr>
      </div>
      <div class="label_element_cover">
      <label>Service</label>
      <select v-model="post_data.service" @click="fetchService" @change="fetchSlip">
        <option
          v-for="(service,i) in services"
          :value="service.id"
          :key="'service_list_'+i"
        >{{service.name}}</option>
      </select>
      </div>
     <!--  <label>Receiver</label>
      <input
        type="text"
        placeholder="Receiver name"
        v-model="post_data.name"
        @keyup.enter="fetchSlip"
      />
      <label>Address</label>
      <input
        type="text"
        placeholder="Enter delivery address"
        v-model="post_data.address"
        @keyup.enter="fetchSlip"
      /> -->
      <div class="bt_generate_label_cover">
      <input type="button" @click="fetchSlip" value="Submit" />
      </div>
    </div>
    <loader v-if="loading" />
    <div class="default_container" v-if="!loading && Object.keys(slip).length > 0">
      <section>
        <div class="form_section_transmit">
          <table class="transmit_table1">
            <tr>
              <td>{{user_detail.branch}}</td>
              <td>SUMMARY TRANSMIT SLIP</td>
              <td>
                <img src="../../assets/temp_logo1_new.png" />
              </td>
            </tr>
          </table>
        </div>
      </section>
      <section>
        <div class="form_section_transmit">
          <table class="transmit_table2">
            <tr>
              <td>
                <!-- INTERNAL
                <br />
                <span>BATCH 3</span>-->
              </td>
              <td>{{user_detail.division}}</td>
              <td>
                <div class="dispatch_date">
                  Date:
                  <!-- Dispatch date: -->
                  <span>{{moment(post_data.date).format("MM-DD-YYYY")}}</span>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </section>
      <section v-if="!loading && Object.keys(slip).length > 0">
        <div class="form_section_transmit" v-for="(couriers,key,i) in slip" :key="'table'+i">
          <h3 class="mb-2">{{key}}</h3>
          <table class="transmit_table3">
            <tr>
              <td>REF</td>
              <td>ADDRESSEE</td>
              <td>ITEM</td>
              <td>ITEM DESCRIPTION</td>
              <td>SENDER</td>
            </tr>
            <tr v-for="(data,j) in couriers" :key="'data'+i+j">
              <td>
                <barcode :value="data.booking_prefix + data.booking_id" tag="img" />
              </td>
              <td>
                <p v-if="data.delivery_name">{{data.delivery_name}}</p>
                <p v-if="data.delivery_address_1">{{data.delivery_address_1}}</p>
                <p v-if="data.delivery_address_2">{{data.delivery_address_2}}</p>
                <p v-if="data.delivery_city">{{data.delivery_city}}</p>
                <p v-if="data.delivery_state">{{data.delivery_state}}</p>
                <p v-if="data.delivery_country">{{data.delivery_country}}</p>
              </td>
              <td>{{data.item_qty}}</td>
              <td>{{data.goods_description || 'NA'}}</td>
              <td>{{data.pickup_name}}</td>
            </tr>
          </table>
        </div>
      </section>
    </div>
    <section class="centered" v-if="Object.keys(slip).length > 0">
      <a class="link_bt bt_cancel" @click="$router.push('/pages/dashboard')">CANCEL</a>
      <a
        class="link_bt bt_save"
        @click="generateSlip"
        :disabled="create_btn.disabled"
      >{{create_btn.value}}</a>
    </section>
    <section v-if="fetch_data && !loading && Object.keys(slip).length == 0">
      <h3>No data found</h3>
    </section>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
  name: "GenerateSlip",
  components: {
    flatPickr
  },
  data() {
    return {
      daterange: "",
      enableTime: true,
      services: [],
      configDate: {
       format: "d-m-Y",
          altFormat: "d-m-Y",
        altInput: true,
        noCalendar: false
      },
      slip: {},
      loading: false,
      fetch_data: false,
      post_data: {
        date: "",
        name: "",
        service: "",
        address: ""
      },
      user_detail: {
        branch: "",
        division: ""
      },
      create_btn: {
        disabled: false,
        value: "GENERATE SLIP"
      }
    };
  },
  created() {
    this.$emit("breadCrumbData", ["Dispatch", "Generate Slip"]);
    this.post_data.date = this.moment(new Date()).format("YYYY-MM-DD");
    this.axios
      .get("/api/user/data")
      .then(response => {
        this.user_detail.branch = response.data.msg.branch.name;
        this.user_detail.division = response.data.msg.division.name;
      })
      .catch(error => {
        this.toast.error(error);
      });
    this.fetchService();
  },
  methods: {
    fetchService() {
      this.axios
        .get("/api/provider/services/list")
        .then(response => {
          this.services = response.data.msg.servicenames;
          if (this.services.length > 0) {
            this.post_data.service = response.data.msg.servicenames[0].id;
          }
        })
        .catch(error => {
          this.toast.error(error);
        });
    },
    fetchSlip() {
      this.fetch_data = true;
      this.loading = true;
      this.slip = {};
      this.axios
        .post("/api/slip/view", this.post_data)
        .then(response => {
          this.slip = response.data.list;
          this.loading = false;
        })
        .catch(error => {
          console.log(e);
          this.loading = false;
        });
    },
    generateSlip() {
      this.create_btn.disabled = true;
      this.create_btn.value = "GENERATING SLIP...";
      this.axios
        .post("api/slip", this.post_data)
        .then(response => {
          this.toast.success("Slip generated successfully");
          this.$router.push("/pages/ViewTransmittalSlipBranch");
          this.create_btn.disabled = false;
          this.create_btn.value = "GENERATE SLIP";
        })
        .catch(e => {
          console.log(e);
          this.create_btn.disabled = false;
          this.create_btn.value = "GENERATE SLIP";
        });
    }
  }
};
</script>
<style scoped  >
.repeate_slip_heading {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  margin-top: 40px;
}
.mb-2 {
  margin-bottom: 1rem;
}

.transmit_table3 img {
  width: 100%;
  height: 60px;
}
</style>


